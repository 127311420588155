<template>
  <d-remote-monitoring-import />
</template>

<script>

export default {
  name: 'RemoteMonitoringImport',
  page: {
    title: 'CRM Doctis - Импорт дистанционный мониторинг',
  },
};
</script>
